import { css } from "styled-components"

export const h1Header = css`
    font-family: "Geogrotesque Medium", "Verdana", "Helvetica";
    font-weight: 300;
    font-size: 120px;
    line-height: 120px;
    margin: 0;
`
export const h2Header = css`
    font-family: "Geogrotesque Medium", "Verdana", "Helvetica";
    font-weight: 300;
    font-size: 100px;
    line-height: 100px;
    margin: 0;
`

export const h3Header = css`
    font-family: "Geogrotesque Regular", "Verdana", "Helvetica";
    font-weight: 300;
    font-size: 50px;
    line-height: 50px;
    margin: 0;
`

export const h4Header = css`
    font-family: "Geogrotesque Regular", "Verdana", "Helvetica";
    font-weight: 300;
    font-size: 35px;
    line-height: 35px;
    margin: 0;
`

export const Body1 = css`
    font-family: "Geogrotesque Regular", "Helvetica";
    font-size: 22px;
    margin: 0;
`
